import React, { useState, useEffect } from "react";
import thatappLogo from "../../assets/icons/thatapplogo.svg";
import radioChecked from "../../assets/icons/radioIconChecked.svg";
import "./NewBilling.css";
import { CircularProgress } from "@mui/material";
import { initiateTrial, saveCard, initiateSub } from "services/sync";
import toaster from "toasted-notes";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { getMe } from "services/auth";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { logout } from "redux/actions/auth";
// import { setUser } from "redux/actions/user";

const NewBilling = ({ userUpgrade, newPlanDetails, activePlan }) => {
  const [selectedOption, setSelectedOption] = useState("option1");
  const [isChecked, setIsChecked] = useState(false);
  const [activateBilling, setActivateBilling] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const user = useSelector((state) => state?.user?.authUser);
  const [cardholderName, setCardholderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [bluesnapToken, setBlueSnapToken] = useState("");
  // const [newVersionPlan, setNewVersionPlan] = useState(null);
  const [cardBrand, setCardBrand] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setBlueSnapToken(me.data.bluesnap_token);
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  const userEmail = user?.user?.email;

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleRadioClick = (e) => {
    if (selectedOption === e.target.value) {
      setSelectedOption(null);
    } else {
      handleRadioChange(e);
    }
  };

  const processPayment = async () => {
    if (isChecked === false) {
      return;
    }

    try {
      setIsloading(true);
      if (isChecked === true) {
        let payload = {
          token: bluesnapToken,
        };
        await initiateTrial(payload);
        toaster.notify("15 days trial period activated successfully.", {
          duration: "4000",
          position: "top",
        });
        setIsloading(false);
        history.push("/connect");
      }
    } catch (e) {
      setIsloading(false);
    }
  };

  const initiateSubscription = async () => {
    try {
      let payload = {
        plan_id: newPlanDetails[0].id,
      };
      await initiateSub(payload);
      history.push("/dashboard/sync");
    } catch (e) {
      setIsloading(false);
    }
  };

  const formatExpirationDate = (value) => {
    const cleaned = value.replace(/\D+/g, "");
    const match = cleaned.match(/^(\d{0,2})(\d{0,4})$/);

    if (match) {
      const month = match[1];
      const year = match[2];
      return `${month}${year ? "/" + year : ""}`;
    }

    return value;
  };

  const detectCardBrand = (number) => {
    const patterns = {
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    };

    if (patterns.visa.test(number)) return "VISA";
    if (patterns.mastercard.test(number)) return "MASTERCARD";
    if (patterns.amex.test(number)) return "AMEX";
    if (patterns.discover.test(number)) return "DISCOVER";
    if (patterns.diners.test(number)) return "DINERS CLUB";
    if (patterns.jcb.test(number)) return "JCB";

    return "";
  };

  useEffect(() => {
    if (activePlan !== null) {
      setSelectedOption("option2");
    }
  }, [activePlan]);

  useEffect(() => {
    if (cardNumber.length === 16) {
      const cardBrandDetected = detectCardBrand(cardNumber);
      setCardBrand(cardBrandDetected);

      // Call the Bluesnap API
      const fetchToken = async () => {
        try {
          const response = await axios.put(
            `https://sandbox.bluesnap.com/services/2/payment-fields-tokens/${bluesnapToken}`,
            {
              ccNumber: cardNumber,
            }
          );
          console.log("Token received:", response.data);
        } catch (error) {
          console.error("Failed to fetch token:", error);
          console.log("Failed to validate card number");
        }
      };

      fetchToken();
    }
  }, [cardNumber, bluesnapToken]);

  useEffect(() => {
    // if (expDate.length !== 7) {
    //   toaster.notify("Enter Card expirey details correctly!", {
    //     duration: "4000",
    //     position: "top",
    //   });
    //   return;
    // }

    if (cvv.length === 3 && expDate.length === 7) {
      const fetchToken = async () => {
        try {
          const response = await axios.put(
            `https://sandbox.bluesnap.com/services/2/payment-fields-tokens/${bluesnapToken}`,
            {
              cvv: cvv,
              expDate: expDate,
            }
          );

          console.log("Token received:", response.data);
        } catch (error) {
          console.error("Failed to fetch token:", error);
          console.log("Failed to validate card number");
        }
      };

      fetchToken();
    }
  }, [expDate, cvv, bluesnapToken]);

  const submitCard = () => {
    setIsloading(true);
    if (cardholderName === "" || cardNumber === "" || !expDate || !cvv) {
      setIsloading(false);
      toaster.notify("Please fill out all card details!", {
        duration: "4000",
        position: "top",
      });
      return;
    }

    const lastFourDigits = cardNumber.slice(-4);
    const cardData = {
      name: cardholderName,
      email: userEmail,
      last_four_digit: lastFourDigits,
      card_expire: expDate,
      cvv: cvv,
      bluesnap_token: bluesnapToken,
      card_brand: cardBrand,
    };
    saveCard(cardData)
      .then((res) => {
        const response = JSON.parse(res.data);
        if (response.message) {
          toaster.notify(
            response.message[0]?.description || "Card saved successfully!",
            {
              duration: "4000",
              position: "top",
            }
          );
        }
        setIsloading(false);
        initiateSubscription();
      })
      .catch((error) => {
        toaster.notify("Failed to add card!", {
          duration: "4000",
          position: "top",
        });
        setIsloading(false);
        return;
      });
  };

  return (
    <div className="billing_cont">
      <div className="d-flex align-items-center justify-content-between">
        <img
          onClick={() => {
            history.push("/");
          }}
          src={thatappLogo}
          alt="logo"
        />
        {user.new_user === false ? (
          <button
            style={{
              border: "1px solid #F0B429",
              color: "#F0B429",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              window.history.back();
            }}
          >
            Back
          </button>
        ) : (
          <button
            style={{
              border: "1px solid #F0B429",
              color: "#F0B429",
              borderRadius: "4px",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              dispatch(logout());
              history.push("/login");
            }}
          >
            Logout
          </button>
        )}
      </div>

      {activateBilling ? (
        <div className="billing_content_cont mt-4">
          <div className="billing_content">
            <div className="billing_header">
              <h4 style={{ color: "#000", fontWeight: "bold" }}>
                Enter Your Payment Details to <br /> Activate Billing
              </h4>
              <p className="mt-2">
                Finalize your subscription and unlock full access to backup and
                sync services.
              </p>
              <hr className="billing__hr" />
            </div>
            <div className="billing_plan-v2 mt-4">
              <p>By subscribing, you agree to the following:</p>
              <ul className="ml-3">
                <li>
                  You will be charged ${newPlanDetails[0]?.price} per month per organisation,
                  whether you reach the 1 million items limit or not.
                </li>

                <li>
                  The ${newPlanDetails[0]?.price} is the minimum charge,
                  regardless of your usage during the month
                </li>

                <li>
                  Any additional services, such as emails, cloning, or database
                  syncing, will be charged separately based on your usage.
                </li>
              </ul>
            </div>

            <div style={{ width: "100%" }} className="services__title__cont">
              <div className="d-flex align-items-center justify-content-between">
                <h4
                  style={{ fontSize: "16px", fontWeight: "600" }}
                  id="transition-modal-title"
                >
                  Payment information:
                </h4>
              </div>

              <div style={{ marginTop: "12px" }} className="card-name ">
                <label className="mb-2" htmlFor="name">
                  Card Holder
                </label>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="cardholder-name"
                  placeholder="Fullname"
                  name="name"
                  autoComplete="name"
                  value={cardholderName}
                  onChange={(e) => setCardholderName(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <div>
                  <label className="mb-2" htmlFor="cvv">
                    Card Number
                  </label>

                  <div className="d-flex align-items-center justify-content-between">
                    <div style={{ width: "100%" }}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        // id="card-number"
                        placeholder="Card Number"
                        value={cardNumber}
                        onChange={(e) => {
                          setCardNumber(e.target.value);
                          const detectedBrand = detectCardBrand(e.target.value);
                          setCardBrand(detectedBrand);
                        }} // Update state on change
                      />
                      <span className="helper-text" id="ccn-help" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-extras">
                <div className="div-inner">
                  <label className="mb-2" htmlFor="exp-date">
                    Exp. (MM/YYYY)
                  </label>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="exp-date"
                    placeholder="MM/YYYY"
                    value={expDate} // Bind state
                    onChange={(e) => {
                      const formattedDate = formatExpirationDate(
                        e.target.value
                      );
                      setExpDate(formattedDate); // Update state with formatted date
                    }}
                    // Update state on change
                  />
                  <span className="helper-text" id="exp-help" />
                </div>

                <div>
                  <label className="mb-2" htmlFor="cvv">
                    CVV
                  </label>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="cvv"
                    placeholder="CVV"
                    value={cvv} // Bind state
                    onChange={(e) => setCvv(e.target.value)} // Update state on change
                  />
                  <span className="helper-text" id="cvv-help" />
                </div>
              </div>
            </div>

            <div
              className="billing__button__cont "
              style={{ marginTop: "30px" }}
            >
              <button
                className="generate_btn"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid black",
                  color: "#000",
                }}
                onClick={() => setActivateBilling(false)}
              >
                Cancel
              </button>
              <div className="billing__btn">
                {isLoading ? (
                  <div className="mt-3 flex justify-content-end">
                    <button className="generate_btn">
                      <CircularProgress sx={{ color: "white" }} size={20} />
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-end">
                    {activateBilling ? (
                      <button
                        onClick={(e) => {
                          submitCard(e);
                          setIsChecked(true);
                        }}
                        className="generate_btn"
                      >
                        Activate billing
                      </button>
                    ) : (
                      <button
                        disable={
                          isChecked === false && !activateBilling ? true : false
                        }
                        style={
                          isChecked === false && !activateBilling
                            ? { backgroundColor: "#CDD0D5" }
                            : { backgroundColor: "#F2B11B" }
                        }
                        className="generate_btn"
                      >
                        {selectedOption === "option1"
                          ? "Activate 15-day trial"
                          : "Proceed to billing"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="billing_content_cont mt-6">
          <div className="billing_content">
            <div className="billing_header">
              <h4 style={{ color: "#000", fontWeight: "800" }}>
                Start Backing Up and Syncing <br /> Your Data Today
              </h4>
              <p style={{ color: "#7E7E7E" }} className="mt-2 mb-0">
                Subscribe now to unlock full data synchronization features
              </p>
              <hr className="billing_hr" />
            </div>
            <div className="billing_plan">
              <p className="mb-0">Plan</p>
              <h4 style={{ fontWeight: "bold", margin: "1% 0" }}>
                ${newPlanDetails[0]?.price}/month per Organisation{" "}
              </h4>
              <p className="mb-0">Includes backup of up to 1 million records</p>
            </div>

            <div className="services__title__cont">
              <p
                style={{
                  color: "#000",
                  fontWeight: "bold",
                }}
                className="mb-0"
              >
                Additional Services Pricing
              </p>
              <p
                style={{ color: "#7E7E7E", fontSize: "14px" }}
                className="mb-0"
              >
                Expand your data protection with these add-ons:
              </p>
              <div
                style={{
                  backgroundColor: "#FBFCFC",
                  padding: "1% 3%",
                  borderRadius: "12px",
                  marginTop: "3%",
                  border: "1px solid #E5E5E5",
                }}
              >
                <div className="billing__pricing__cont mt-3">
                  {newPlanDetails[0]?.feature_add_ons?.map((item, index) => {
                    return (
                      <div key={index} className="billing_plan_feature">
                        <p className="mb-0">{item.title}</p>
                        <p className="mb-0">
                          {" "}
                          <span className="billing_amt">
                            ${item.value}
                          </span>{" "}
                          {item.title === "Clone" ? "/record" : "/month"}
                        </p>
                        {item.title === "Print" ? (
                          <p className="mb-0" style={{ color: "#F2B11B" }}>
                            {" "}
                            Per 1000 print
                          </p>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                <div className="services__title__cont mb-3">
                  <p
                    style={{ color: "#000", fontWeight: "700" }}
                    className="mb-0"
                  >
                    Database Types:
                  </p>
                  <div
                    style={{ marginTop: "12px" }}
                    className="billing__feature__price__cont "
                  >
                    <div className="billing_base">
                      <p className="mb-0">MySQL</p>
                      <h4 style={{ fontWeight: "bold" }}>$10.00</h4>
                    </div>
                    <div className="billing_base">
                      <p className="mb-0">PostgreSQL</p>
                      <h5 style={{ fontWeight: "bold" }}>$10.00</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="billing_trial_hr" />

            {activePlan === null && (
              <div className="billing__radio__cont services__title__cont">
                <div className="d-flex align-items-center ">
                  {selectedOption === "option1" ? (
                    <img
                      onClick={() => setIsChecked(false)}
                      src={radioChecked}
                      alt=""
                    />
                  ) : (
                    <input
                      style={{
                        appearance: "auto",
                        width: "20px",
                        height: "20px",
                        accentColor: "#F7C948",
                      }}
                      value="option1"
                      checked={selectedOption === "option1"}
                      onChange={handleRadioChange}
                      onClick={handleRadioClick}
                      type="radio"
                    />
                  )}

                  <p className="ml-2 mb-0">Proceed to 15-day trial</p>
                </div>
                <div className="d-flex align-items-center">
                  {selectedOption === "option2" ? (
                    <img
                      onClick={() => setIsChecked(false)}
                      src={radioChecked}
                      alt=""
                    />
                  ) : (
                    <input
                      style={{
                        appearance: "auto",
                        width: "20px",
                        accentColor: "#F7C948",
                      }}
                      value="option2"
                      onChange={handleRadioChange}
                      checked={selectedOption === "option2"}
                      onClick={handleRadioClick}
                      type="radio"
                    />
                  )}

                  <p className="ml-2 mb-0">Proceed to Subscribe</p>
                </div>
              </div>
            )}
            <div className="billing_info mt-4">
              {selectedOption === "option1" ? (
                <div>
                  <p className="option_head mb-0">Not ready to subscribe?</p>
                  <p className="mb-0">
                    When you activate billing, you'll be charged $30 per month
                    for each organisation, no matter if you hit the 1 million
                    item limit or not. Any add-ons you choose might come with
                    extra costs. Billing happens monthly, but you’re free to
                    cancel anytime before the next billing cycle to avoid being
                    charged for the following month.
                  </p>
                </div>
              ) : activePlan === null || selectedOption === "option2" ? (
                <div>
                  <p className="mb-0">
                    You can start with a 15-day free trial and store up to 250
                    items per application. To keep backing up and syncing your
                    data after the trial, you’ll need to subscribe. If no
                    payment is made, your trial data will be deleted.
                  </p>
                </div>
              ) : null}
            </div>

            <div className="mt-4 d-flex align-items-start mb-5">
              <input
                style={{
                  appearance: "auto",
                  width: "24px",
                  marginTop: "5px",
                  height: "auto",
                  accentColor: "#F7C948",
                }}
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p className="mb-0 ml-2">
                By checking this box, you are agreeing to our{" "}
                <a
                  style={{ color: "#F7C948" }}
                  href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of service
                </a>
                ,{" "}
                <a
                  style={{ color: "#F7C948" }}
                  href="/pricing"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Pricing
                </a>{" "}
                and{" "}
                <a
                  style={{ color: "#F7C948" }}
                  href="https://help.thatapp.io/en/articles/108584-privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy{" "}
                </a>
              </p>
            </div>

            <div className="billing__button__cont">
              <button
                className="generate_btn"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid black",
                  color: "#000",
                }}
                onClick={() => {
                  if (activateBilling === true) {
                    setActivateBilling(false);
                  } else if (
                    user.free_trial === true ||
                    user.active_plan !== null
                  ) {
                    window.history.back();
                  } else {
                    history.push("/service");
                  }
                }}
              >
                Cancel
              </button>

              <div className="billing__btn">
                {isLoading ? (
                  <button className="generate_btn">
                    <CircularProgress sx={{ color: "white" }} size={20} />
                  </button>
                ) : (
                  <button
                    disable={isChecked === false ? true : false}
                    onClick={() => {
                      if (selectedOption === "option1") {
                        processPayment();
                      } else {
                        if (isChecked === false) {
                          return;
                        } else {
                          setActivateBilling(true);
                        }
                      }
                    }}
                    style={
                      isChecked === false
                        ? { backgroundColor: "#CDD0D5" }
                        : { backgroundColor: "#F2B11B" }
                    }
                    className="generate_btn "
                  >
                    {selectedOption === "option1"
                      ? "Activate 15-day trial"
                      : "Proceed to billing"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewBilling;
