import React from 'react'
import arrRight from "../../Images/arrow-right-hero.png";
import "./innovation.css";
import { Link } from 'react-router-dom';

const PricingSection = () => {
  return (
    <div className="pricing__section__cont">
      <div className="pricing__title__cont">
        <h2>
          Subscribe now to unlock <span>full data protection</span> and{" "}
          <span>synchronization features.</span>
        </h2>
        <p>
          We agonize over the right distractions so your teams don't need to
          worry about data loss or connections to other systems
        </p>
      </div>
      <div className="pricing__plans">
        <div className="standard__plan">
          <div>
            <h2>Standard Plan</h2>
            <h5>Enjoy full data protection and synchronization features.</h5>
            <div className="std__h3__cont">
              <h3>$30/month per org</h3>
            </div>
            <ul className="std__plan__list">
              <li>Backup & Sync: 1 million records per organization</li>
              <li>Automatic Syncing: Across multiple devices</li>
              <li>Data Insights: Real-time tracking and monitoring</li>
            </ul>
          </div>
          <Link to="/register">
            <button className="std_plan_but">
              <p>Subscribe</p>
              <img src={arrRight} alt="get sarted" />
            </button>
          </Link>
        </div>

        <div className="service__plan">
          <div>
            <div className="service__title__cont">
              <h2>Additional Services </h2>
              <h5>Expand your data protection with these add-ons</h5>
            </div>
            <div className="serv__grid__cont">
              <div className="double__section">
                <div className="email__price">
                  <h3>Email</h3>
                  <p>
                    $5.00<span>/month</span>
                  </p>
                </div>
                <div className="cloning__price">
                  <h3>Cloning</h3>
                  <p>
                    $0.05<span>/month</span>
                  </p>
                </div>
              </div>
              <div className="single__section">
                <h3>Database syncing</h3>
                <p>
                  $10.00 <span>/month</span>
                </p>

                <h4>Database Types</h4>
                <ul>
                  <li>MySQL - $10.00</li>
                  <li>PostgreSQL - $10.00</li>
                  <li>MS SQL - $10.00</li>
                </ul>

                <h6>Total: $30.00/month</h6>
              </div>
            </div>
          </div>
          <Link to="/register">
            <button className="std_serv_but">
              <p>Get started now</p>
              <img src={arrRight} alt="get sarted" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PricingSection