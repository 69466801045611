import axios from "../config";
import asyncHandler from "../utils/asyncHandler";

export const registerUser = asyncHandler(
  async (data) => await axios.post(`/auth/signup`, data)
);

export const loginUser = asyncHandler(
  async (data) => await axios.post(`/auth/login`, data)
);

export const podioAuthUser = asyncHandler(
  async (data) => await axios.post(`/auth/authorize_podio`, data)
);

export const requestPasswordReset = asyncHandler(
  async (data) => await axios.post(`/auth/password/forgot`, data)
);

export const resetPassword = asyncHandler(
  async (data) => await axios.put(`/auth/password/reset`, data)
);

export const requestAccountVerification = asyncHandler(
  async (data) => await axios.post(`/auth/verify/resend`, data)
);

export const verifyEmail = asyncHandler(
  async (query) => await axios.post(`/auth/verify_token${query}`)
);

export const getMe = asyncHandler(async () => await axios.get(`/auth/me`));

export const authorization_check = asyncHandler(
  async (query) => await axios.post(`/auth/authorization_check${query}`)
);

export const imitate_account = asyncHandler(
  async (query) => await axios.post(`/auth/imitate_account${query}`)
);

export const imitate_partner_account = asyncHandler(
  async (query) => await axios.post(`/auth/imitate-partner${query}`)
);

export const authConnector = asyncHandler(
  async (data) => await axios.post(`/connector/authorize/callback${data}`)
);

export const fileHavenAuthConnector = asyncHandler(
  async (data) => await axios.post(`/sync/podio/file/auth${data}`)
);

export const disconnectPodioFilehaven = asyncHandler(
  async (data) => await axios.post(`/sync/podio/file/auth/disconnect`)
);

export const authorisePodioPartner = asyncHandler(
  async (data) => await axios.post(`/authorize/podio-partner`, data)
);

export const authTmailConnector = asyncHandler(
  async (data) => await axios.post(`/tmail/auth`, data)
);