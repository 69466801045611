import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import downIcon from "../../assets/icons/downIcon.svg";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useHistory } from "react-router";
import "./Header.css";
import { connect, useDispatch } from "react-redux";
import { setUser } from "../../redux/actions/user";
import { setAuditOrg } from "../../redux/actions/orgs";
import Axios from "config";
import {
  USER_ACCOUNT,
  PLANS,
  MY_INVOICES,
  PLATFORM_USERS,
  PLATFORM_REFFERALS,
  MY_PARTNERS,
} from "../../containers/pagePath";
import { getMe } from "../../services/auth";
import { logout } from "../../redux/actions/auth";
import Swal from "sweetalert2";
import AppSwitch from "../Navigation/AppSwitchSync";
import Tooltip from "@material-ui/core/Tooltip";
import RenderByPermission from "components/RenderByPermission";
import ProgressBar from "components/ProgressBar";
import ProgressBarStyles from "components/ProgressBar/ProgressBar.module.scss";
import { useSyncLoadingContext } from "context/LoadingContext/SyncLoadingContext";
import PdcModal from "components/PodioDataCloneModal/PdcModal";
import menuProfile from "../../assets/icons/menuProfile.svg";
import menuDisconnect from "../../assets/icons/menuDisconnect.svg";
import menuLogout from "../../assets/icons/menuLogout.svg";
import menuInvoice from "../../assets/icons/menuInvoice.svg";
import menuPlatform from "../../assets/icons/menuPlatform.svg";
import menuPlans from "../../assets/icons/menuPlans.svg";
import menuReferrals from "../../assets/icons/menuReferrals.svg";
import menuPartners from "../../assets/icons/menuPartners.svg";
import menuDashboards from "../../assets/icons/menuDashboard.svg";

const isTeamMember = localStorage.getItem("team_member_uuid");

const drawerWidth = 260;
const useStyles = makeStyles((theme) => ({
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  paper: {
    marginRight: theme.spacing(2),
    border: "1px solid red",
  },
  popperStyle: {
    left: "-10px !important",
    width: "fit-content !important",
  },
  listItemStyle: {
    minWidth: "40px !important",
  },
}));

const Header = ({ baropen, handleDrawerOpen, setUser, user }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const [prl, setPrl] = React.useState(user?.podio_profile?.rate_limit);
  const dispatch = useDispatch();
  const [img, setImg] = React.useState(
    isTeamMember
      ? "https://i.stack.imgur.com/l60Hf.png"
      : user?.podio_profile?.profile?.image
  );
  // eslint-disable-next-line no-unused-vars
  const [deleting, setDeleting] = React.useState(false);
  const [userData, setUserData] = useState("");

  const { isLoading } = useSyncLoadingContext();

  // PODIO DATA CLONE MODAL - PDC Modal
  const [openPdcModal, setOpenPdcModal] = React.useState(false);

  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setUserData(me.data);
        })
        .catch((e) => {
          // toast.notify("An error occurred, try refreshing this page", {
          //   type: "error",
          // });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  const handlePdcClose = () => {
    setOpenPdcModal(false);
  };
  // PODIO DATA CLONE MODAL

  function handleDisconnectFromPodio() {
    setDeleting(true);
    Axios.post("/sync/podio/disconnect")
      .then(() => {
        getMe().then((response) => {
          dispatch(setUser(response.data));
          window.location = "/connect";
        });
      })
      .catch(console.error)
      .finally(() => setDeleting(false));
  }

  function refreshRateLimit() {
    getMe()
      .then((res) => {
        setPrl(res.data.podio_profile?.rate_limit);
        setImg(
          isTeamMember
            ? "https://i.stack.imgur.com/l60Hf.png"
            : user?.podio_profile?.profile?.image
        );
      })
      .catch(() => {
        setPrl(null);
        setImg(null);
      });
  }

  var allowedUserId = [1129, 4992, 4801];

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let name = "";
  let email = "";
  if (user?.team_member) {
    name = user?.team_member?.name;
    email = user?.team_member?.email;
  } else {
    name = user?.name;
    email = user?.email;
  }

  let role = "";
  if (userData?.new_billing === true) {
    role = "Dynamic billing";
  } else {
    role =
      userData?.free_trial === true &&
      userData?.active_plan === null &&
      userData.new_billing === false
        ? "Free trial"
        : userData?.free_trial === false && userData?.active_plan
        ? userData.active_plan.planName
        : userData?.active_plan
        ? userData.active_plan.planName
        : "";
  }
  return (
    <>
      <div className={ProgressBarStyles.progressBar_container}>
        <ProgressBar isLoading={isLoading} />
      </div>
      <div className="header">
        <AppBar
          position="fixed"
          className={clsx(
            classes.appBar,
            {
              [classes.appBarShift]: baropen,
            },
            "headerCont"
          )}
        >
          <Toolbar>
            <div className="user__nav d-flex justify-between w-full">
              <div style={{ margin: "auto" }}>
                <AppSwitch />
              </div>

              <div className="user">
                <div style={{ marginRight: "20px" }}>
                  <small className="price_rate_indicator">
                    Podio rate limit:{" "}
                    <b>{prl ? Number(prl.rate_limit).toLocaleString() : 0}</b>
                    <i
                      className="fas fa-sync"
                      style={{
                        fontSize: "1.2em",
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => refreshRateLimit()}
                      title="Refresh to get the latest podio rate-limit"
                    ></i>
                  </small>
                  <br />
                  <small className="price_rate_indicator">
                    Podio rate limit remaining:{" "}
                    <b>
                      {prl
                        ? Number(prl.rate_limit_remaining).toLocaleString()
                        : 0}
                    </b>
                  </small>
                </div>

                <div className="user__image__container ">
                  <Avatar
                    alt={name ? name : user?.podio_profile?.profile?.name}
                    src={img}
                  />
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <div
                      className="headerNameCont"
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      onClick={handleToggle}
                      style={{ cursor: "pointer" }}
                    >
                      {name ? name : user?.podio_profile?.profile?.name}
                      {/* <i
                      className="fas fa-angle-down"
                      style={{
                        position: "relative",
                        top: "1px",
                        marginLeft: "15px",
                      }}
                    ></i> */}
                      <small className="d-block user__info__wrapper">
                        {email}
                      </small>

                      <small
                        style={
                          userData?.free_trial === true &&
                          userData?.active_plan === null &&
                          userData?.new_billing === false
                            ? {
                                backgroundColor: "#FFF9EA",
                                color: "#F0B429",
                                borderRadius: "20px",
                                textAlign: "center",
                                padding: "2%",
                                minWidth: "100px",
                              }
                            : userData?.free_trial === false &&
                              userData?.active_plan === null &&
                              userData?.new_billing === false
                            ? {
                                backgroundColor: "#fff",
                                color: "#40C3F7",
                                borderRadius: "20px",
                                textAlign: "center",
                                padding: "2%",
                                minWidth: "100px",
                              }
                            : {
                                backgroundColor: "#E3F8FF",
                                color: "#40C3F7",
                                borderRadius: "20px",
                                textAlign: "center",
                                padding: "2%",
                                minWidth: "100px",
                              }
                        }
                        className="d-block user__info__wrapper"
                      >
                        {role}
                      </small>
                    </div>
                    <div onClick={handleToggle} className="ml-2 dropIcon">
                      <img alt="" src={downIcon} />
                    </div>
                  </div>
                  <Popper
                    className={`nav__popup ${classes.popperStyle}`}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper className={classes.paperStyle}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <RenderByPermission>
                                <>
                                  <MenuItem
                                    onClick={(e) => {
                                      history.push(USER_ACCOUNT);
                                      handleClose(e);
                                    }}
                                  >
                                    <img
                                      className="mr-2"
                                      src={menuProfile}
                                      alt="Profile Icon"
                                    />
                                    My account
                                  </MenuItem>
                                  <RenderByPermission
                                    allowTeamMemberAccess={false}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        history.push(MY_INVOICES);
                                        handleClose(e);
                                      }}
                                    >
                                      <img
                                        src={menuInvoice}
                                        alt="Invoice Icon"
                                        className="mr-2"
                                      />
                                      My Invoices
                                    </MenuItem>
                                  </RenderByPermission>
                                  <RenderByPermission
                                    allowTeamMemberAccess={false}
                                  >
                                    {user.type && (
                                      <MenuItem
                                        onClick={(e) => {
                                          history.push(PLATFORM_USERS);
                                          handleClose(e);
                                        }}
                                      >
                                        <img
                                          src={menuPlatform}
                                          alt="Platform Icon"
                                          className="mr-2"
                                        />
                                        Platform Users
                                      </MenuItem>
                                    )}
                                  </RenderByPermission>
                                  {/* <RenderByPermission allowTeamMemberAccess={false}> */}

                                  {/* </RenderByPermission> */}

                                  {user.user_is_podio_partner && (
                                    <MenuItem
                                      onClick={(e) => {
                                        history.push(PLATFORM_REFFERALS);
                                        handleClose(e);
                                      }}
                                    >
                                      <img
                                        className="mr-2"
                                        src={menuReferrals}
                                        alt="Referrals Icon"
                                      />
                                      My Referrals
                                    </MenuItem>
                                  )}
                                  {allowedUserId.includes(
                                    user?.database.user_id
                                  ) && (
                                    <RenderByPermission
                                      allowTeamMemberAccess={false}
                                    >
                                      <MenuItem
                                        onClick={(e) => {
                                          history.push(MY_PARTNERS);
                                          handleClose(e);
                                        }}
                                      >
                                        <img
                                          className="mr-2"
                                          src={menuPartners}
                                          alt="Partners Icon"
                                        />
                                        My Partner
                                      </MenuItem>
                                    </RenderByPermission>
                                  )}

                                  {user?.admin_url ? (
                                    <RenderByPermission
                                      allowTeamMemberAccess={false}
                                    >
                                      <MenuItem
                                        onClick={() => {
                                          window.open(
                                            user?.admin_url,
                                            "_blank",
                                            "noopener,noreferrer"
                                          );
                                        }}
                                      >
                                        <img
                                          className="mr-2"
                                          src={menuDashboards}
                                          alt="Dashboards Icon"
                                        />
                                        Admin Dashboard
                                      </MenuItem>
                                    </RenderByPermission>
                                  ) : null}
                                  <RenderByPermission
                                    allowTeamMemberAccess={false}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        history.push(PLANS);
                                        handleClose(e);
                                      }}
                                    >
                                      <img
                                        className="mr-2"
                                        src={menuPlans}
                                        alt="Plans Icon"
                                      />
                                      Plans
                                    </MenuItem>
                                  </RenderByPermission>
                                  <RenderByPermission
                                    allowTeamMemberAccess={false}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        Swal.fire({
                                          title: "Remove Podio",
                                          text: "Your podio account would be removed from sync",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#d33",
                                          cancelButtonColor: "#3085d6",
                                          confirmButtonText: "Remove Account",
                                          cancelButtonText: "Cancel",
                                        }).then((result) => {
                                          if (result.value) {
                                            handleDisconnectFromPodio();
                                          }
                                        });
                                      }}
                                    >
                                      <img
                                        className="mr-2"
                                        src={menuDisconnect}
                                        alt="Disconnect Icon"
                                      />
                                      Disconnect Podio
                                    </MenuItem>
                                  </RenderByPermission>
                                </>
                              </RenderByPermission>
                              <MenuItem
                                onClick={() => {
                                  dispatch(logout());
                                  history.push("/login");
                                }}
                                style={{
                                  backgroundColor: "#FFF5F5",
                                  color: "#E12D39",
                                  borderRadius: "8px",
                                  marginTop: "10%",
                                  marginLeft: "5%",
                                  marginRight: "5%",
                                }}
                              >
                                <img
                                  className="mr-2"
                                  style={{ marginLeft: "-6%" }}
                                  src={menuLogout}
                                  alt="logout Icon"
                                />
                                Logout
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </div>
            </div>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, "menuCont")}
              style={{ margin: "0" }}
            >
              <Tooltip title="Menu icon">
                <MenuIcon />
              </Tooltip>
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>

      <PdcModal open={openPdcModal} handleClose={handlePdcClose} />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUser(user)),
  setAuditOrg: (data) => dispatch(setAuditOrg(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
