import React from "react";
import lock from "../../../assets/icons/lockIcon.svg";
import unlock from "../../../assets/icons/errorIcon.svg";
import { makeStyles } from "@material-ui/core";
import Polygon from "../../../assets/icons/Polygon.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0 1%",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.1)",
    marginTop: "1%",
    color: "black",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    margin: "4% 1% 0 1%",
    width: "100%",
    alignItems: "center",
  },
  redBtn: {
    backgroundColor: "#E12D39",
    color: "white",
    padding: "1% 3%",
    borderRadius: "50px",
    border: "none",
    cursor: "pointer",
    width: "159px",
    height: "40px",
    fontSize: "14px",
  },
  yellowBtn: {
    backgroundColor: "#fff",
    color: "#F7C948",
    padding: "1% 1%",
    border: "none",
    cursor: "pointer",
    width: "fit-content",
    marginBottom: "2%",
    fontSize: "13px",
    fontWeight: "bold",
  },
  infoText: {
    display: "flex",
    margin: "2% 0 0 0",
    fontSize: "15px",
  },
  image: {
    width: "4%",
  },
  imageInd: {
    position: "absolute",
    marginLeft: "-90%",
    marginTop: "-23%",
    width: "30px",
  },
}));

const WorkflowPop = ({
  isProtected,
  org,
  handleWorkPopClose,
  orgId,
  count,
  fileCount,
}) => {
  const classes = useStyles();

  return (
    <div onMouseLeave={handleWorkPopClose} className={classes.container}>
      <img className={classes.imageInd} src={Polygon} alt="" />
      <div className={classes.header}>
        {isProtected === true ? (
          <img className={classes.image} src={lock} alt="lock" />
        ) : (
          <img className={classes.image} src={unlock} alt="unlock" />
        )}
        <div style={{ width: "100%", textAlign: "center" }}>
          <h5 style={{ fontSize: "12px", fontWeight: "700" }}>
            Backed Up Workflows
          </h5>
        </div>
      </div>
      <div className={classes.infoText}>
        {isProtected === true ? (
          <h5 style={{ fontSize: "12px" }}>
            Workflows connection is still active{" "}
          </h5>
        ) : (
          <h5 style={{ fontSize: "12px" }}>
            Workflows connection is currently not active kindly reconnect
          </h5>
        )}
      </div>
      <div style={{ width: "100%" }}>
        {isProtected === true ? null : (
          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between align-items-center mt-1"
          >
            <button
              onClick={() => {
                window.open(
                  "https://chromewebstore.google.com/detail/thatapp-tools-for-podio/phclnlgnabofihjdpocflflhkjddljff?hl=en-GB&utm_source=ext_sidebar",
                  "_blank"
                );
              }}
              className={classes.yellowBtn}
            >
              Link to extension
            </button>
            <button
              onClick={() => {
                window.open(
                  "https://workflow-automation.podio.com/flows.php",
                  "_blank"
                );
              }}
              className={classes.yellowBtn}
            >
              Connect now
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkflowPop;
