import React from "react";
import "./innovation.css";
import ontime from "../../Images/ontime.png";
import insight from "../../Images/insight.png";
import automate from "../../Images/automate.png";
import connections from "../../Images/connections.png";
import arrRight from "../../Images/arrow-right-hero.png";
import { Link } from "react-router-dom";

export default function Innovation() {
  return (
    <div className="outer-cont">
      <div className="step-text-cont">
        <h2>
          An innovative approach to <span> backing up </span> and
          <span> synchronizing </span>
          your data
        </h2>
        <p>
          We agonize over the right distractions so your teams don't need to
          worry about data loss or connections to other systems
        </p>
      </div>
      <div className="step-cont">
        <div className="step">
          <img src={insight} alt="insight" />
          <p>
            Gain insight into your data and understand how they are
            interconnected
          </p>
        </div>
        <div className="step">
          <img src={connections} alt="insight" />
          <p>
            We have integrations and connections to a lot of systems thereby
            making data migration seamless and easy
          </p>
        </div>
        <div className="step">
          <img src={ontime} alt="insight" />
          <p>
            Our systems operate with 99.9%+ uptime and are highly scalable and
            redundant
          </p>
        </div>
        <div className="step">
          <img src={automate} alt="insight" />
          <p>
            Automate every aspect of your business and cut out time-intensive,
            repetitive tasks
          </p>
        </div>
      </div>
      <Link to="/register">
        <button className="started-innovation-button">
          <p>Get started now</p>
          <img src={arrRight} alt="get sarted" />
        </button>
      </Link>
    </div>
  );
}
