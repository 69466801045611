import React, { useEffect } from "react";
// website
// import Home from "./containers/Website/pages/Home";
// import PricingThatapp from "./containers/Website/pages/Pricing";
// import SyncFeatures from "./containers/Website/pages/SyncFeatures";
// import PrintFeatures from "./containers/Website/pages/PrintFeatures";
// import AvaFeatures from "./containers/Website/pages/AvaFeatures";
import Integrations from "./containers/Website/pages/Integrations";
//
// import Register from "./containers/Authentication/Register/Register";
// import Login from "./containers/Authentication/Login/Login";
import ForgotPassword from "./containers/Authentication/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/Authentication/ResetPassword/ResetPassword";
import VerifyEmail from "./containers/Authentication/VerifyEmail/VerifyEmail";
import RequestEmailVerification from "./containers/Authentication/RequestEmailVerification/RequestEmailVerification";
import Pricing from "./containers/Pricing";
import Connector from "./containers/Connector/Connector";
import UnauthenticatedRoute from "./utils/UnauthenticatedRoute";
import Dashboard from "./containers/Dashboard/Dashboard";
import Iris from "./containers/Iris/Iris";
import IrisNew from "./containers/Iris/iris-new";
import ChooseService from "./containers/ChooseService/ChooseService";
import AuthenticatedRoute from "./utils/AuthenticatedRoute";
import ConfirmEmail from "./containers/Authentication/ConfirmEmail/ConfirmEmail";
import { Switch } from "react-router";
import AuditServerSide from "./containers/Audit/AuditServerSide";
import Live from "./containers/Trail/Live";
import NotFound from "./components/NotFound";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import PodioAuthorizationError from "./containers/Authentication/ConfirmEmail/PodioAuthorizationError";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Authorizepass from "./containers/Authentication/Login/AuthorizepassV2";
import ImitateAccount from "./containers/ImitateAccount";
import Connections from "./pages/AuthPages/Print/Connections";
import TmailConnections from "./pages/AuthPages/Tmail/Connections";
import {
  CONNECT_PRINT,
  PRINT_HOME,
  PRINT_DATA_EDIT,
  PRINT_CREATE_TEMPLATE,
  PRINT_RIGHT_SIGNATURE_REDIRECT,
  CONNECT_TMAIL,
  TMAIL_DASHBOARD,
} from "./containers/pagePath";
import PrintEditor from "./components/templates/print-v2/PrintEditor";
import { getMe } from "./redux/actions/user";
import { store } from "./redux/store";
import { Router, Route } from "react-router-dom";
import history from "./helpers/history";
import CreateTemplate from "./components/templates/print-v2/AddTemplate/CreateTemplate";
import PrintHome from "./components/templates/print-v2/PrintHome/PrintHome";
import PublicForm from "./containers/Items/PublicForm/PublicForm";
//MysqlConnector
// import SqlConnector from './containers/Mysql/Connector';
import AuditView from "./containers/Audit/AuditView";
import TmailDashboard from "./containers/Tmail/Dashboard/Dashboard";
import TmailMessage from "./containers/TmailMessage/Message";
// import TmailMessageBox from './containers/Tmail/Tmessage/TmailMessageBox';
import AuthorisePodioPartner from "./containers/Authentication/AuthorisePodioPartner/AuthorisePodioPartner";
import NewTmail from "./components/Tmail/Tmail";
import TmailMessageBoxV2 from "./containers/Tmail/Tmessage/TmailMessageBoxV2";
import ShowOrphanMessage from "./containers/Tmail/Tmessage/ShowOrphanMessage";
import ShowOrphanMessageV2 from "./containers/Tmail/Tmessage/ShowOrphanMessageV2";

import { ToastContainer as ToastifyContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrintRightSignatureRedirect from "components/templates/print-v2/PrintRightSignatureRedirect/PrintRightSignatureRedirect";
import AccountOverview from "containers/MyAccount/AccountOverview";
// import Homepage from "containers/NewWebsite/pages/Homepage";
import Integration from "containers/NewWebsite/pages/Integration";
import Contact from "containers/NewWebsite/pages/Contact";
import Homepage from "containers/NewWebsite/pages/Homepage";
import IntegrationNew from "containers/NewWebsite/pages/Integration.jsx";
import PricingNew from "containers/NewWebsite/pages/Pricing.jsx";
import FAQs from "containers/NewWebsite/pages/FAQs";
import SyncFeaturesNew from "./containers/NewWebsite/pages/SyncFeatures";
import PrintFeaturesNew from "./containers/NewWebsite/pages/PrintFeatures";
import AvaFeaturesNew from "./containers/NewWebsite/pages/AvaFeatures";
import NewRegister from "./containers/Authentication/Register/NewRegister";
import NewLogin from "containers/Authentication/Login/NewLogin";

const App = () => {
  useEffect(() => {
    if (store.getState().user.authUser.isLoggedIn) store.dispatch(getMe());
  });
  const theme = createTheme({
    palette: {
      primary: {
        main: "#fcaf3b",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["Mulish", "sans-serif", "Roboto"].join(","),
    },
  });

  return (
    <div>
      <Router history={history}>
        <ToastifyContainer theme="colored" />
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/integrations" component={IntegrationNew} />
            <Route exact path="/FAQs" component={FAQs} />
            <Route
              path="/draft-mail/:itemId/:uuid"
              component={TmailMessageBoxV2}
            />
            {/* <Route
              path='/compose-message/:itemId/:uuid'
              component={TmailMessageBoxV2}
            /> */}
            <Route
              path="/show_message/:emailId"
              component={ShowOrphanMessage}
            />
            <Route
              path="/item-moderate/:uuid/:app_id/:unique_id/:orgId"
              component={ShowOrphanMessageV2}
            />
            <Route
              path="/compose-message/:uuid/:app_id/:unique_id"
              component={TmailMessageBoxV2}
            />
            <Route exact path="/pricing" component={PricingNew} />
            <Route exact path="/account_overview" component={AccountOverview} />
            {/* <Route exact path="/features/sync" component={SyncFeatures} />
            <Route exact path="/features/print" component={PrintFeatures} />
            <Route exact path="/features/ava" component={AvaFeatures} /> */}
            <Route exact path="/features/sync" component={SyncFeaturesNew} />
            <Route exact path="/features/print" component={PrintFeaturesNew} />
            <Route exact path="/features/ava" component={AvaFeaturesNew} />
            <Route exact path="/integration" component={Integrations} />
            <Route exact path="/new_homepage" component={Homepage} />
            <Route exact path="/new_integration" component={Integration} />
            <Route exact path="/new_contact" component={Contact} />
            <UnauthenticatedRoute
              exact
              path="/register"
              component={NewRegister}
            />

            <UnauthenticatedRoute exact path="/login" component={NewLogin} />
            <UnauthenticatedRoute
              exact
              path="/authorizepass"
              component={Authorizepass}
            />
            <UnauthenticatedRoute
              exact
              path="/forgotpassword"
              component={ForgotPassword}
            />
            <UnauthenticatedRoute
              exact
              path="/password/reset"
              component={ResetPassword}
            />
            <UnauthenticatedRoute
              exact
              path="/podio-authorization-error"
              component={PodioAuthorizationError}
            />
            <UnauthenticatedRoute
              path="/account/verification"
              component={VerifyEmail}
            />
            <UnauthenticatedRoute
              path="/account/resend"
              component={RequestEmailVerification}
            />
            <AuthenticatedRoute path="/pricing" component={Pricing} />
            <UnauthenticatedRoute
              path="/account/confirm-email"
              component={ConfirmEmail}
            />
            <Route
              path="/public/form/:user_id/:org_id/:app_id"
              render={(props) => (
                <React.Fragment>
                  <PublicForm />
                </React.Fragment>
              )}
            />
            <UnauthenticatedRoute path="/test" component={Live} />
            <AuthenticatedRoute path="/connect/:id?" component={Connector} />
            <AuthenticatedRoute
              exact
              path={CONNECT_PRINT}
              component={Connections}
            />
            <AuthenticatedRoute exact path={PRINT_HOME} component={PrintHome} />
            <AuthenticatedRoute
              exact
              path={PRINT_CREATE_TEMPLATE}
              component={CreateTemplate}
            />
            <AuthenticatedRoute
              exact
              path={PRINT_DATA_EDIT}
              component={PrintEditor}
            />
            <AuthenticatedRoute
              exact
              path={PRINT_RIGHT_SIGNATURE_REDIRECT}
              component={PrintRightSignatureRedirect}
            />
            <AuthenticatedRoute
              exact
              path="/service"
              component={ChooseService}
            />
            <AuthenticatedRoute path="/dashboard" component={Dashboard} />
            <AuthenticatedRoute
              path="/imitate-account"
              component={ImitateAccount}
            />
            <AuthenticatedRoute
              path="/audit/:orgId"
              component={AuditServerSide}
            />

            <AuthenticatedRoute path="/audit" component={AuditView} />
            <AuthenticatedRoute path="/iris" component={Iris} />
            <AuthenticatedRoute path="/iris-new" component={IrisNew} />
            {/* <AuthenticatedRoute path='/connector' component={SqlConnector} /> */}

            <AuthenticatedRoute
              path="/tmail-message/:id"
              component={TmailMessage}
            />
            <AuthenticatedRoute path="/tmail" component={NewTmail} />

            {/* <AuthenticatedRoute
              path='/new-tmail-message/:id'
              component={TmailMessageBox}
            />


            <AuthenticatedRoute
              path='/new/:app_id/:unique_id'
              component={TmailMessageBox}
            /> */}

            <AuthenticatedRoute
              exact
              path={CONNECT_TMAIL}
              component={TmailConnections}
            />

            <AuthenticatedRoute
              path={TMAIL_DASHBOARD}
              component={TmailDashboard}
            />

            <Route
              path="/authorise/podio-partner"
              component={AuthorisePodioPartner}
            />

            <Route component={NotFound} />
          </Switch>
        </MuiThemeProvider>
      </Router>
    </div>
  );
};

export default App;
