import React, { useState } from "react";
import "./faq.css";
import plus from "../../Images/plus.png";
import minus from "../../Images/minus.png";
import arrRight from "../../Images/arrow-right-hero.png";
import { Link } from "react-router-dom";

export default function FAQ() {
  const faqs = [
    {
      question: "What is Sync in thatapp.io, and why should I care?",
      answer:
        "Sync ensures your Podio data is always up-to-date across platforms. No more manual updates—just seamless, automated data syncing to keep your business running smoothly.",
    },
    {
      question: "Why is the Print system useful for my workflow?",
      answer:
        "Our print system lets you generate beautifully formatted documents from Podio data instantly. Streamline reports, invoices, and client deliverables with just a click!",
    },
    {
      question: "How do I Resync my data in thatapp.io?",
      answer:
        "Navigate to your synced organization table on the sync dashboard, click the 'Action' dropdown of the organization, click the \"Resync,\" button and choose whether to sync all spaces or specific apps, ensuring your data is always current.",
    },
    {
      question: "How do I restore my deleted files?",
      answer:
        "File Restoration is not impossible on thatapp.io . To restore your deleted Podio file, get this done within the synced organisation structure of the sync interface. The deleted file will not be on the active file table, you will have to toggle to the deleted file and restore from there.",
    },
  ];

  const [isOpen, setIsOpen] = useState([]);

  function handleClick(index) {
    if (isOpen.includes(index)) {
      setIsOpen((opens) => opens.filter((num) => num !== index));
    } else {
      setIsOpen((opened) => [...opened, index]);
    }
  }

  return (
    <div className="faq-cont">
      <div className="faq-text-cont">
        <h2>Frequently Asked Questions</h2>
        <p>
          If the question is not available on our FAQ section, Feel free to
          contact us personally, we will resolve your respective doubts.
        </p>
      </div>
      <div className="faq-questions-cont">
        {faqs.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => handleClick(index)}>
              <h2>{item.question}</h2>
              <img
                src={isOpen.includes(index) ? minus : plus}
                alt="open or close"
              />
            </div>
            <div
              className={`faq-answer ${
                isOpen.includes(index) ? "faq-open" : "faq-close"
              }`}
            >
              <div className="overflow-hidden">
                <hr />
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Link to="/FAQs" className="faq-button">
        <p>See more FAQs</p>
        <img src={arrRight} alt="get sarted" />
      </Link>
    </div>
  );
}
