import React from "react";
import "./footer.css";
import arrRight from "../../Images/arrow-right-white.png";
import logo from "../../Images/logo.png";
import { Link } from "react-router-dom";

export default function Footer(e) {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-cont">
      <div
        className="footer-get-started"
        style={{ backgroundColor: `${e.bg}` }}
      >
        <h2>Ready to get started?</h2>
        <p>
          Let us bring you up to speed on automation, syncing, and gaining
          insight on your data
        </p>
        <Link to="/register">
          <p>Get started now</p>
          <img src={arrRight} alt="get started" />
        </Link>
      </div>
      <div className="footer-links">
        <div className="footer-logo-cont">
          <Link to="/">
            <img src={logo} alt="thatapp logo" />
          </Link>
          <h4 className="thatapp-info">
            It's our job to ensure that you're never lost in the clouds. Gain a
            refreshing perspective of your business processes and strategic
            initiatives.
          </h4>
          <h6>© {currentYear} ThatApp, powered by Techego LLC</h6>
        </div>
        <div className="other-footer-links">
          <h2>Products</h2>
          <Link to="/features/sync">
            <p>Sync</p>
          </Link>
          <Link to="/features/ava">
            <p>Ava</p>
          </Link>
          <Link to="/features/print">
            <p>Print</p>
          </Link>
          <Link to="/integrations">
            <p>Integrations</p>
          </Link>
          <a
            href="https://thatapp1.statuspage.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Status page</p>
          </a>
          <Link to="/pricing">
            <p>Pricing</p>
          </Link>
        </div>
        <div className="other-footer-links">
          <h2>AVA iPaaS Documentation</h2>
          <a
            href="https://docs.thatapp.io/getting-started/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Get started</p>
          </a>
          <a href="https://docs.thatapp.io/guides/" target="_blank" rel="noopener noreferrer">
            <p>Integrator guide</p>
          </a>
          <a
            href="https://docs.thatapp.io/components/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Components</p>
          </a>
          <a
            href="https://docs.thatapp.io/developers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Components developers</p>
          </a>
          <a
            href="https://docs.thatapp.io/releases/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Releases</p>
          </a>
          <a
            href="https://docs.thatapp.io/references/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>References</p>
          </a>
          <a
            href="https://docs.thatapp.io/admin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Support</p>
          </a>
        </div>
        <div className="other-footer-links">
          <h2>Help Article</h2>
          <a
            href="https://help.thatapp.io/en/collections/209696-sync"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Sync</p>
          </a>
          <a
            href="https://help.thatapp.io/en/collections/209662-print"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Print</p>
          </a>
          <a
            href="https://help.thatapp.io/en/collections/5300754-releases"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Product release notes</p>
          </a>
          <div onClick={() => window.Intercom("show")} id="intercom_link">
            <p>Contact us</p>
          </div>
        </div>
        <div className="other-footer-links">
          <h2>Other</h2>
          <div
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/articles/108584-privacy-policy",
                "_blank"
              );
            }}
          >
            <p>Privacy Policy</p>
          </div>
          <div
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/articles/9392123-terms-of-service",
                "_blank"
              );
            }}
          >
            <p>Terms and conditions</p>
          </div>
          <div
            onClick={() => {
              window.open(
                "https://help.thatapp.io/en/articles/9867786-pricing-policy",
                "_blank"
              );
            }}
          >
            <p>Pricing policy</p>
          </div>
        </div>
      </div>
    </div>
  );
}
